<template>
  <el-dialog title="黑名单" :visible.sync="dialogVisible" width="1000px" :before-close="handleClose">
    <div class="d-f ai-c">
      <el-input style="width: 200px; margin-right: 20px" placeholder="搜索黑名单" v-model="content"></el-input>
      <el-button @click="search">搜索</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="name" label="姓名" width="180"> </el-table-column>
      <el-table-column prop="phone" label="手机号" width="180">
      </el-table-column>
      <el-table-column prop="IDcard" label="身份证号"> </el-table-column>
      <el-table-column prop="universityName" label="学校" width="180"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">移除黑名单</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { $delete, $get } from '../../plugins/axios';
const { mapState, mapActions } = createNamespacedHelpers("card");
export default {
  name: "blackList",
  data() {
    return { dialogVisible: false, tableData: [], content: "" };
  },
  methods: {
    ...mapActions(["getBlackList", "removeBlack"]),
    handleClick(item) {
      this.$confirm('移除黑名单操作', '是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          $delete('/lock/blackList/'+item.id).then(()=>{
            this.search()
          })
        })
     },
    show() {
      this.dialogVisible = true;
      this.search()
    },
    search() {
      $get('/lock/blackList').then(res => {
        this.tableData = res
      })
    },
  },
  mounted() {
    this.search()
  },
};
</script>

<style>

</style>