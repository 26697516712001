<template>
	<div class="h-100_" style="width:500px">
		<content-header title="app设置">
		</content-header>
		<el-form ref="form" :model="form" label-width="180px" style="margin-top:10px">
			<el-form-item label="非会员开锁视频广告时长">
				<el-input onkeyup="this.value=this.value.replace(/\D/g,'')" v-model="form.appAdTime" type="number">
					<template slot="append">秒</template>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="save">保存</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import ContentHeader from "@/components/contentHeader.vue";
import { $get, $post, $put } from "../../plugins/axios";
export default {
	name: "app",
	components: {
		ContentHeader
	},
	data() {
		return {
			id: null,
			form: {
				appAdTime: 5
			}
		};
	},
	computed: {
	},
	methods: {
		getParams() {
			$get('/lock/systemParameter/getSystemParameterByCode', { params: { paramCode: "advertiseTime" } }).then(res => {
				this.form.appAdTime = +res.paramValue;
				this.id = res.id
			})
		},
		save() {
			$put('/lock/systemParameter', { paramCode: "advertiseTime", id: this.id, paramValue: this.form.appAdTime }).then(res => {

			})
		}
	},
	mounted() {
		this.getParams()
	},
};
</script>

<style>

</style>