<template>
  <div>
    <el-dialog title="发帖子" :visible.sync="dialogVisible" width="1000px">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="选择主题">
          <el-tag style="margin: 0 5px; cursor: pointer" v-for="item in topicList" @click="form.topic = item.value"
            disable-transitions :key="item.value" :type="item.value == form.topic ? '' : 'info'">{{
              item.label
            }}</el-tag>
        </el-form-item>
        <el-form-item label="帖子内容">
          <my-editor v-model="form.content"></my-editor>
        </el-form-item>
        <el-form-item label="上传图片">
          <div class="d-f ai-c fw-w">
            <div class="upload-img" v-for="url in form.postPicList" :key="url.picAddr">
              <el-image style="width: 100px; height: 100px" :src="imageUpload + url.picAddr"
                :preview-src-list="form.postPicList.map(it => imageUpload + it.picAddr)">
              </el-image>
            </div>
            <div v-if="form.postPicList.length <= 6" class="upload-img d-f ai-c jc-c"
              style="cursor: pointer; border: 1px dotted #ccc" @click="$refs.file.click()">
              <i slot="default" class="el-icon-plus"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即{{ isEdit? '更新': '创建' }}</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <input type="file" @change="change" ref="file" style="width: 0; height: 0; opacity: 0" accept="image/*" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import myEditor from "../../components/myEditor.vue";
const { mapState, mapActions } = createNamespacedHelpers("card");
const { mapState: systemMapState } = createNamespacedHelpers("system");
import { topicList } from "./common";
export default {
  components: { myEditor },
  name: "blackList",
  data() {
    return {
      dialogVisible: false,
      topicList,
      isEdit: false,
      form: {
        topic: 1,
        content: "",
        postType: 0,
        userId: 0,
        postPicList: [
        ].map((it, idx) => ({
          picAddr: it,
          orderNum: idx,
        })),
      },
    };
  },
  methods: {
    ...mapActions(["getBlackList", "removeBlack", "upload", "addTableData", "modifyTableData"]),
    handleClick(item) { },
    onSubmit() {
      if (this.isEdit) {
        this.modifyTableData(this.form).then(() => {
          this.dialogVisible = false;
        })
      } else {

        this.addTableData(this.form).then(() => {
          this.dialogVisible = false;
        });
      }
    },
    show(item) {
      this.isEdit = !!item;
      this.form = {
        topic: 1,
        content: "",
        postType: 0,
        userId: 0,
        postPicList: [
        ]
      };
      if (this.isEdit) {

        this.form = {
          id: item.id,
          topic: item.topic,
          content: item.content,
          postType: 0,
          userId: 0,
          postPicList: item.postPicList
        }
      }
      this.dialogVisible = true;
    },
    search() {
      this.getBlackList();
    },
    change(e) {
      const file = Array.from(e.target.files)[0];
      console.log(file);
      this.upload(file).then((res) => {
        this.form.postPicList.push({
          picAddr: res
        })
      });
    },
  },
  mounted() { },
  computed: {
    ...systemMapState(['imageUpload'])
  }
};
</script>

<style>
.upload-img {
  width: 100px;
  height: 100px;
  margin-right: 5px;
}
</style>