<template>
  <div class="h-100_">
    <content-header title="帖子管理">
      <div class="d-f ai-c w-100_">
        <my-input style="width: 500px" placeholder="输入文字搜索帖子（昵称、手机号、姓名、文字）" v-model="content"></my-input>
        <el-switch style="margin: 0 20px" @change="search" v-model="postType" active-value="1" inactive-value="0"
          active-color="#E66700" inactive-color="#E66700" active-text="用户贴" inactive-text="系统贴">
        </el-switch>
        <el-button @click="search">搜索</el-button>
        <el-button @click="$refs.create.show()">发帖子 +</el-button>
        <el-button type="text" @click="$refs.black.show()">黑名单</el-button>
      </div>
    </content-header>
    <content-table ref="table" :total="total" :columns="columns" :setting="setting" :getTableData="getTableDataList"
      :tableList="tableList" @delete="deleteItem" @edit-outline="edit" @lablack="lablack"></content-table>
    <black-list ref="black"></black-list>
    <create ref="create"></create>
  </div>
</template>

<script>
import ContentHeader from "@/components/contentHeader.vue";
import ContentTable from "@/components/contentTable.vue";
import BlackList from "./blackList.vue";
import Create from "./createCard.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("card");
const { mapState: systemMapState } = createNamespacedHelpers("system");
import { topicList } from './common'
import { $delete } from "../../plugins/axios";
export default {
  name: "card",
  components: {
    ContentHeader,
    ContentTable,
    BlackList,
    Create,
  },
  data() {
    return {
      postType: 0,
      content: ""
    };
  },
  computed: {
    ...mapState(["total", "tableList", "mmPath"]),
    ...systemMapState(['imageUpload']),
    setting() {
      return this.postType == 1 ? ["lablack", "delete"] : ['edit-outline', 'delete']
    },
    columns() {
      const ls = this.postType == 1 ? [{ label: "发帖人姓名", prop: "userName", width: "120" },
      {
        label: "手机号",
        prop: "phone",
        width: "120",
      }] : []
      return [
        ...ls,
        { label: "类型", prop: "postType", width: "100", list: ['系统贴', '用户贴'] },
        { label: "主题", prop: "topic", width: "120", list: ['', ...topicList.map(it => it.label)] },
        { label: "文字内容", prop: "content", view: true },
        // { label: "管理员密码", prop: "password" },

        {
          label: "图片",
          prop: "postPicList",
          width: "200",
          imgType: true,
          imgList: list => list.map(it => this.imageUpload+it.picAddr)
        },
        {
          label: "发帖时间",
          prop: "createTime",
          width: "200",
          date: true,
        },
      ]
    }
  },
  methods: {
    ...mapActions([
      "getTableData",
      "addTableData",
      "modifyTableData",
      "deleteTableData",
      "addBlackList"

    ]),
    getTableDataList(val) {
      this.getTableData(val);
    },
    lablack(item) {
      this.$prompt('', '请输入拉黑原因', {

      }).then(({ value }) => {
        this.addBlackList({
          "universityId": item.universityId,
          "userId": item.userId,
          "name": item.userName,
          "phone": item.phone,
          "identityNo": item.identityNo,
          "universityName": item.universityName,
          "reason": value,
        })
      })

    },
    search() {
      this.$refs.table.getDataInit({
        postType: this.postType,
        content: this.content,
      });
    },
    edit(item) {
      this.$refs.create.show(item)
    },
    deleteItem(item) {
      this.$confirm(
        "确定要删除这条帖子吗？",
        "删除提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        $delete(`/lock/postInfo/${item.id}`).then(res => this.search());
      });
    }
  },
  mounted() {
    this.search();
  },
};
</script>

<style>

</style>