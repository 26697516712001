export const topicList = [
	{
		value: 1,
		label: "学习"
	},
	{
		value: 2,
		label: "兼职"
	},
	{
		value: 3,
		label: "转让"
	},
	{
		value: 4,
		label: "生活"
	},
	{
		value: 5,
		label: "学习资料"
	},
	{
		value: 6,
		label: "其他"
	},

]